// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { Injectable } from '@movecloser/front-core';
import { log } from '@support/log';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import { LoginEvent, SignUpEvent, Trackify, TRACKIFY_DEBUG, TRACKIFY_GTM } from '@kluseg/trackify/dist/main';
import { PageViewEvent } from '@/modules/analytics/events/page-view.event';
import { SubscribeEvent } from '@/modules/analytics/events/subscribe.event';
import { CommentAddEvent } from '@/modules/analytics/events/comment-add.event';
import { PaywallTransactionEvent } from '@/modules/analytics/events/paywall-transaction.event';
import { now } from 'lodash';
/**
 * Class to observe common UI changes and send analytics to GA4
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
let GAObserver = class GAObserver {
    constructor(windowService) {
        this.windowService = windowService;
        this.isInit = false;
        this.unsentStack = [];
        this.unsentUE = [];
        this.UELimit = 20;
        if (typeof process.env.VUE_APP_GA_KEY === 'string' && String(process.env.VUE_APP_GA_KEY).length > 0) {
            this.trackify = new Trackify();
            this.trackify.useDriver(TRACKIFY_GTM, { layerId: 'dataLayer' });
            if (process.env.VUE_APP_GA_DEBUG === 'true') {
                this.trackify.useDriver(TRACKIFY_DEBUG);
            }
            this.trackify.loadDrivers().then(() => {
                this.isInit = true;
            });
        }
        else {
            log('[GAObserver]: Cannot instantiate Trackify. [GA_KEY] missing', 'warn');
        }
    }
    /**
     * List of observables
     * @protected
     */
    get observableEvents() {
        return {
            'newsletter-subscribe': 'onSubscribe',
            'app:page.loaded': 'onPageView',
            'app:article.view': 'onArticleView',
            'app:author.view': 'onAuthorView',
            'app:category.view': 'onCategoryView',
            'auth:logged-in': 'onLogin',
            'auth:sign-up': 'onSignUp',
            'comments:add': 'onCommentAdd',
            'subscription:begin': 'onBuySubscription'
        };
    }
    /**
     * Function to track page changes
     * @private
     */
    onPageView(event) {
        if (!this.trackify)
            return;
        const ev = new PageViewEvent(event);
        // FIXME should be in userComService
        setTimeout(() => {
            var _a;
            if (typeof window.UE !== 'undefined' &&
                window.UE.isReady) {
                window.UE.pageHit({
                    URL: event.path || window.location.pathname,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    user_id: (_a = event.userComId) !== null && _a !== void 0 ? _a : null
                });
            }
        }, 300);
        // EOF should be in userComService
        if (!this.isInit) {
            this.unsentStack.push(ev);
        }
        else {
            this.considerUnsentEvents();
            setTimeout(() => {
                if (this.trackify) {
                    this.trackify.track(ev);
                }
            });
        }
    }
    /**
     * Function to track page changes
     * @private
     */
    onArticleView(event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        if (!this.trackify)
            return;
        dayjs.extend(utc);
        dayjs.extend(timezone);
        const date = dayjs.tz((_a = event.article) === null || _a === void 0 ? void 0 : _a.publishedAt, 'Europe/Warsaw');
        const pubDate = date.utc().format('YYYY-MM-DDTHH:mm:ssZ');
        this.sendUEEvent('product_event', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            product_id: 'article_' + ((_b = event.article) === null || _b === void 0 ? void 0 : _b.id),
            // eslint-disable-next-line @typescript-eslint/camelcase
            event_type: 'view',
            name: event.title,
            domain: (_d = (_c = event.article) === null || _c === void 0 ? void 0 : _c.domain) !== null && _d !== void 0 ? _d : null,
            description: (_f = (_e = event.article) === null || _e === void 0 ? void 0 : _e.description) !== null && _f !== void 0 ? _f : null,
            teaser: (_h = (_g = event.article) === null || _g === void 0 ? void 0 : _g.teaser) !== null && _h !== void 0 ? _h : null,
            labels: (_k = (_j = event.article) === null || _j === void 0 ? void 0 : _j.labels) !== null && _k !== void 0 ? _k : null,
            tags: (_m = (_l = event.article) === null || _l === void 0 ? void 0 : _l.tags) !== null && _m !== void 0 ? _m : null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            availability_date: pubDate !== null && pubDate !== void 0 ? pubDate : null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            publication_date: pubDate !== null && pubDate !== void 0 ? pubDate : null,
            link: (_p = (_o = event.article) === null || _o === void 0 ? void 0 : _o.url) !== null && _p !== void 0 ? _p : null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            is_video: (_r = (_q = event.article) === null || _q === void 0 ? void 0 : _q.isVideo) !== null && _r !== void 0 ? _r : null,
            'Image URL': (_t = (_s = event.article) === null || _s === void 0 ? void 0 : _s.imageUrl) !== null && _t !== void 0 ? _t : null
        });
    }
    /**
     * Function to track page changes
     * @private
     */
    onAuthorView(event) {
        var _a, _b, _c, _d;
        if (!this.trackify)
            return;
        this.sendUEEvent('product_event', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            product_id: (_b = 'author_' + ((_a = event.article) === null || _a === void 0 ? void 0 : _a.authorId)) !== null && _b !== void 0 ? _b : '',
            // eslint-disable-next-line @typescript-eslint/camelcase
            event_type: 'view',
            name: (_d = (_c = event.article) === null || _c === void 0 ? void 0 : _c.author) !== null && _d !== void 0 ? _d : ''
        });
    }
    /**
     * Function to track page changes
     * @private
     */
    onCategoryView(event) {
        var _a, _b;
        if (!this.trackify)
            return;
        this.sendUEEvent('product_event', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            product_id: 'category_' + ((_a = event.article) === null || _a === void 0 ? void 0 : _a.categoryId),
            // eslint-disable-next-line @typescript-eslint/camelcase
            event_type: 'view',
            name: (_b = event.article) === null || _b === void 0 ? void 0 : _b.category
        });
    }
    /**
     * Function to track page changes
     * @param data
     */
    onSubscribe(data) {
        if (!this.trackify) {
            return;
        }
        this.trackify.track(new SubscribeEvent({
            // eslint-disable-next-line @typescript-eslint/camelcase
            user_id: data.userId
        }));
    }
    /**
     * Function to track page changes
     * @private
     */
    onLogin(user) {
        if (!this.trackify)
            return;
        setTimeout(() => {
            var _a;
            if (typeof window.UE !== 'undefined' &&
                window.UE.isReady) {
                window.UE.pageHit({
                    URL: window.location.pathname,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    user_id: (_a = user.userComId) !== null && _a !== void 0 ? _a : null
                });
                setTimeout(() => {
                    window.userengage('event.login', {
                        email: user.email,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        user_id: user.userComId,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        first_name: user.firstName,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        last_name: user.lastName,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        nick_name: user.nickname,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        user_uniq_id: user === null || user === void 0 ? void 0 : user.uniqueId
                    });
                    window.userengage('client.update', {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        first_name: user.firstName,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        last_name: user.lastName,
                        email: user.email,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        email_hash: user === null || user === void 0 ? void 0 : user.uniqueId,
                        nickName: user.nickname
                    });
                }, 500);
            }
        }, 300);
        this.trackify.track(new LoginEvent({
            method: 'web',
            firstname: user.firstName,
            lastname: user.lastName,
            email: user.email,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_id: user.id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            user_id: user.uniqueId
        }));
    }
    /**
     * Function to track page changes
     * @private
     */
    onSignUp(user) {
        var _a;
        if (!this.trackify)
            return;
        if (typeof window.UE !== 'undefined' &&
            window.UE.isReady) {
            window.UE.pageHit({
                URL: window.location.pathname,
                // eslint-disable-next-line @typescript-eslint/camelcase
                user_id: (_a = user.userComId) !== null && _a !== void 0 ? _a : null
            });
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const date = dayjs.tz(now(), 'Europe/Warsaw');
            const createdAt = date.utc().format('YYYY-MM-DDTHH:mm:ssZ');
            setTimeout(() => {
                window.userengage('event.sign_up', {
                    email: user.email,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    nick_name: user.nickname,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    user_id: user.userComId,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    user_uniq_id: user === null || user === void 0 ? void 0 : user.uniqueId,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    account_created: createdAt
                });
                window.userengage('client.update', {
                    email: user.email,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    nick_name: user.nickname,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    user_id: user.userComId,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    user_uniq_id: user === null || user === void 0 ? void 0 : user.uniqueId,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    account_created: createdAt
                });
            }, 500);
        }
        this.trackify.track(new SignUpEvent({
            method: 'web',
            firstname: user.firstName,
            lastname: user.lastName,
            email: user.email,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_id: user.id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            user_id: user.uniqueId
        }));
    }
    /**
     * Function to track add comments
     */
    onCommentAdd() {
        if (!this.trackify)
            return;
        this.sendUEEvent('event.comment_add', {});
        this.trackify.track(new CommentAddEvent({}));
    }
    /**
     * Function to track add comments
     */
    onBuySubscription(data) {
        if (!this.trackify)
            return;
        this.sendUEEvent('event.subscription_submit', data);
        this.trackify.track(new PaywallTransactionEvent({
            // eslint-disable-next-line @typescript-eslint/camelcase
            subscription_id: data.subscriptionId,
            // eslint-disable-next-line @typescript-eslint/camelcase
            subscription_variant: data.subscriptionVariant,
            // eslint-disable-next-line @typescript-eslint/camelcase
            subscription_price_netto: data.subscriptionPriceNetto,
            // eslint-disable-next-line @typescript-eslint/camelcase
            subscription_price: data.subscriptionPrice,
            // eslint-disable-next-line @typescript-eslint/camelcase
            subscription_duration: data.subscriptionDuration
        }));
    }
    sendUEEvent(name, payload) {
        if (typeof window.UE !== 'undefined' &&
            window.UE.isReady) {
            window.userengage(name, payload);
        }
        else {
            this.unsentUE.push({
                name,
                payload
            });
            this.waitForUE();
        }
    }
    waitForUE() {
        if (this.UETimeout) {
            return;
        }
        this.UETimeout = setTimeout(() => {
            this.UETimeout = undefined;
            if (typeof window.UE !== 'undefined' && window.UE.isReady) {
                this.unsentUE.map(e => this.sendUEEvent(e.name, e.payload));
            }
            else if (this.UELimit) {
                this.UELimit--;
                this.waitForUE();
            }
            else {
                console.warn('[GAObserver] UE not ready, limit of tries exceeded.');
            }
        }, 100);
    }
    considerUnsentEvents() {
        if (this.unsentStack.length === 0) {
            return;
        }
        const cloned = [...this.unsentStack];
        for (const e of cloned) {
            setTimeout(() => {
                if (this.trackify) {
                    this.trackify.track(e);
                    this.unsentStack.shift();
                }
            });
        }
    }
};
GAObserver = __decorate([
    Injectable()
], GAObserver);
export { GAObserver };
export const GAEventObserverType = Symbol.for('GAEventObserver');
