// Copyright © 2021 Move Closer
import Vue from 'vue';
export const getSentry = () => {
    const isClient = typeof window !== 'undefined';
    return (isClient ? import('@sentry/vue') : import('@sentry/node'));
};
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const initSentry = async () => {
    const isClient = typeof window !== 'undefined';
    const Sentry = await getSentry();
    const dsn = isClient ? process.env.VUE_APP_SENTRY_DSN_FRONT : process.env.VUE_APP_SENTRY_DSN_SSR;
    Sentry.init({
        dsn,
        environment: process.env.VUE_APP_ENV,
        release: process.env.VUE_APP_VERSION,
        integrations: [
        // new CaptureConsole({
        //   levels: ['error', 'warn']
        // })
        ],
        attachProps: true,
        logErrors: true,
        Vue
    });
};
