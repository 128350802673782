import { __decorate } from "tslib";
import { ArticleSocials, prepareImage, EmbedModuleUi, ImageWithCaption, GalleryModuleUi, ModuleDriver, resolveDateFnsLocale } from '@d24/modules/front';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatRelative } from 'date-fns';
import { DateTimeType, EventbusType, WindowType } from '@movecloser/front-core';
import NoSSR from 'vue-no-ssr';
import { Inject } from '@plugin/inversify';
import { ShareServiceType } from '@service/share';
/*
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
let LiveFeedEntry = class LiveFeedEntry extends Vue {
    constructor() {
        super(...arguments);
        /**
         * The present as `Date` object
         */
        this.now = new Date();
        /**
         * Element to be displayed as addon
         */
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.addonElement = null;
        /**
         * Addon element props
         */
        this.addonProps = {};
        /**
         * If element is recently fetched
         */
        this.isNew = false;
    }
    /**
     * Date object from entry 'createdAt'
     */
    get date() {
        if (!this.entry) {
            return null;
        }
        return new Date(this.entry.createdAt * 1000);
    }
    /**
     * Converts markdown content into html
     */
    get demarkedContent() {
        if (!this.entry.content) {
            return '';
        }
        const obj = this.DeMarked.convertMDToObjects(this.entry.content);
        if (!obj) {
            return '';
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        return this.DeMarked.convertObjectsToHTML(obj);
    }
    /**
     * Article's publication time as `PublicationTime` enum.
     */
    get humanDate() {
        if (!this.date) {
            return null;
        }
        return formatRelative(new Date(this.date), new Date(), { locale: resolveDateFnsLocale(Object.assign({}, this)) });
    }
    /**
     * Vue created lifecycle hook
     */
    created() {
        this.resolveContentType(this.entry);
        this.handleNewEntry();
    }
    /**
     * Manage new entry status and badge display
     */
    handleNewEntry() {
        if (this.entry.isNew) {
            this.isNew = true;
            setTimeout(() => {
                this.now = new Date();
                this.entry.isNew = false;
                this.isNew = false;
            }, 5000);
        }
    }
    /**
     * Returns time part in two digit format
     */
    formatDate(date) {
        return date < 10 ? `0${date}` : `${date}`;
    }
    /**
     * Pass url to share service
     * http://hostname.com/path-to-article?entry=${entryId}
     */
    onSocialClick(social) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.shareService.share(social, {
            url: `${window.location.origin}${window.location.pathname}?entry=${this.entry.id}`
        });
    }
    /**
     * Decides how to handle different addon type
     */
    resolveContentType(entry) {
        let resolver = null;
        if (entry.gallery) {
            resolver = this.resolveGallery;
        }
        else if (entry.image) {
            resolver = this.resolveImage;
        }
        else if (entry.embed && !Array.isArray(entry.embed)) {
            resolver = this.resolveEmbed;
        }
        if (typeof resolver === 'function') {
            resolver(entry);
        }
    }
    /**
     * Handler for gallery related type
     */
    resolveGallery(entry) {
        if (!entry.gallery) {
            return;
        }
        try {
            this.addonElement = GalleryModuleUi;
            this.addonProps = {
                module: {
                    content: {
                        gallery: entry.gallery
                    }
                }
            };
        }
        catch (err) {
            this.$logger(err, 'error');
        }
    }
    /**
     * Handler for image related type
     */
    async resolveImage(entry) {
        try {
            const element = await this.relatedService.resolve(entry.image);
            if (!element) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const image = prepareImage(element);
            this.addonElement = ImageWithCaption;
            this.addonProps = {
                image: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    ...image
                }
            };
        }
        catch (err) {
            this.$logger(err, 'error');
        }
    }
    /**
     * Handler for embed related type
     */
    resolveEmbed(entry) {
        this.addonElement = EmbedModuleUi;
        this.addonProps = {
            module: {
                content: {
                    ...entry.embed
                },
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                version: entry.embed.type,
                driver: ModuleDriver.Embed
            }
        };
    }
};
__decorate([
    Prop({ required: true })
], LiveFeedEntry.prototype, "DeMarked", void 0);
__decorate([
    Prop({ required: true })
], LiveFeedEntry.prototype, "entry", void 0);
__decorate([
    Prop({ required: true })
], LiveFeedEntry.prototype, "relatedService", void 0);
__decorate([
    Inject(DateTimeType)
], LiveFeedEntry.prototype, "dateTime", void 0);
__decorate([
    Inject(EventbusType)
], LiveFeedEntry.prototype, "eventBus", void 0);
__decorate([
    Inject(ShareServiceType)
], LiveFeedEntry.prototype, "shareService", void 0);
__decorate([
    Inject(WindowType)
], LiveFeedEntry.prototype, "windowService", void 0);
LiveFeedEntry = __decorate([
    Component({
        name: 'LiveFeedEntry',
        components: { ArticleSocials, NoSSR }
    })
], LiveFeedEntry);
export { LiveFeedEntry };
export default LiveFeedEntry;
